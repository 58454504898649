import React, { useContext } from 'react'
import ColorContext from '../../context/ColorContext'

const Wholistic = ({ style }) => {
	const { color } = useContext(ColorContext)
	return (
		<svg
			id="svgWholistic"
			className="svgInline"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 225 75.79"
			style={style}
		>
			<linearGradient id="wholisticGradient" gradientUnits="userSpaceOnUse" x1="0" y1="37" x2="225" y2="37" gradientTransform="matrix(1 0 0 -1 0 66)">
				<stop  offset="0" style={{ stopColor: color.bravo }} />
				<stop  offset="1" style={{ stopColor: color.alpha }} />
			</linearGradient>
			<path fill="url(#wholisticGradient)" d="M182.28 73.99H42.85c-8.39 0-15.82-1.57-22.1-4.66-6.36-3.14-11.38-7.5-14.9-12.97C2.3 50.86.5 44.67.5 37.96s1.8-13.01 5.35-18.47c3.52-5.42 8.52-9.77 14.88-12.95 6.29-3.14 13.72-4.73 22.11-4.73h139.44c8.39 0 15.83 1.59 22.13 4.73 6.35 3.18 11.35 7.54 14.83 12.97 3.5 5.47 5.27 11.67 5.27 18.46s-1.77 12.89-5.27 18.38c-3.49 5.48-8.49 9.84-14.85 12.98-6.28 3.09-13.71 4.66-22.1 4.66h.01ZM42.85 8.22c-7.38 0-13.86 1.37-19.25 4.06-5.32 2.66-9.48 6.26-12.37 10.7-2.9 4.46-4.32 9.36-4.32 14.97s1.41 10.42 4.32 14.92c2.89 4.48 7.04 8.08 12.35 10.69 5.38 2.66 11.87 4 19.26 4h139.44c7.4 0 13.88-1.34 19.27-4 5.3-2.61 9.43-6.21 12.27-10.68 2.83-4.45 4.27-9.48 4.27-14.93s-1.44-10.58-4.26-14.99c-2.85-4.43-6.99-8.03-12.3-10.69-5.39-2.7-11.87-4.06-19.25-4.06H42.85Z" strokeWidth="0"/>
			<path fill="url(#wholisticGradient)" d="M47.15 52.64h-3.69l-6.11-14.61-6.21 14.61h-3.66l-9.06-22.83h6.42l4.93 13.46 5.72-13.46h3.82l5.68 13.42 5.06-13.42h6.21l-9.11 22.83Z" strokeWidth="0"/>
			<path fill="url(#wholisticGradient)" d="M58.29 19.02h6.16v13.64c.77-1.11 1.71-1.99 2.86-2.61 1.14-.64 2.45-.95 3.92-.95 2.52 0 4.53.82 6.03 2.45s2.24 3.88 2.24 6.75v14.35h-6.16V39.09c0-1.46-.35-2.63-1.05-3.48s-1.7-1.27-3-1.27-2.49.48-3.43 1.43c-.93.96-1.4 2.25-1.4 3.9v12.98H58.3V19.02h-.01Z" strokeWidth="0"/>
			<path fill="url(#wholisticGradient)" d="M111.54 19.02h6.16v33.62h-6.16V19.02Z" strokeWidth="0"/>
			<path fill="url(#wholisticGradient)" d="M127.36 26.63c-.59.55-1.32.83-2.21.83s-1.62-.28-2.21-.83c-.59-.55-.88-1.23-.88-2.05s.29-1.52.88-2.06c.59-.54 1.32-.8 2.21-.8s1.62.26 2.21.8.88 1.22.88 2.06-.29 1.5-.88 2.05Z" strokeWidth="0"/>
			<path fill="url(#wholisticGradient)" strokeWidth="0" d="M122.08 29.8h6.16v22.83h-6.16z"/>
			<path fill="url(#wholisticGradient)" d="M134.71 45.42c.59.99 1.43 1.79 2.51 2.35s2.24.86 3.48.86 2.11-.24 2.82-.71 1.05-1.11 1.05-1.94c0-.55-.14-.99-.42-1.29-.28-.31-.6-.54-.97-.68s-.67-.24-.9-.26c-.38-.06-.91-.14-1.58-.26s-1.31-.22-1.89-.31c-.29-.06-.8-.17-1.53-.34-.73-.16-1.5-.47-2.31-.9-.8-.44-1.5-1.08-2.09-1.92-.59-.84-.88-1.93-.88-3.27 0-1.52.4-2.86 1.19-4 .79-1.14 1.88-2.04 3.26-2.68 1.38-.65 2.94-.97 4.66-.97s3.43.34 5 1.01 2.88 1.58 3.94 2.73l-3.87 3.52c-.47-.85-1.13-1.51-1.98-1.98s-1.82-.71-2.9-.71-1.91.22-2.55.64c-.65.43-.97 1.01-.97 1.74s.28 1.22.84 1.47c.55.25 1.05.41 1.5.47.41.06.99.14 1.74.26.74.12 1.4.22 1.95.31.29.02.8.13 1.52.31s1.47.49 2.27.95c.79.46 1.47 1.13 2.05 2s.86 2.04.86 3.48c0 1.62-.42 3.02-1.26 4.22-.84 1.2-1.98 2.15-3.43 2.82s-3.13 1.01-5.03 1.01c-2.05 0-3.96-.36-5.72-1.08-1.76-.72-3.22-1.75-4.4-3.1l4.09-3.74h-.04Z" strokeWidth="0"/>
			<path fill="url(#wholisticGradient)" d="M156.91 34.68h-5.06V29.8h5.06v-6.64h6.16v6.64h5.98v4.88h-5.98v17.96h-6.16V34.68Z" strokeWidth="0"/>
			<path fill="url(#wholisticGradient)" d="M176.48 26.63c-.59.55-1.32.83-2.21.83s-1.62-.28-2.21-.83c-.59-.55-.87-1.23-.87-2.05s.29-1.52.87-2.06c.59-.54 1.32-.8 2.21-.8s1.62.26 2.21.8c.59.54.87 1.22.87 2.06s-.29 1.5-.87 2.05Z" strokeWidth="0"/>
			<path fill="url(#wholisticGradient)" strokeWidth="0" d="M171.2 29.8h6.16v22.83h-6.16z"/>
			<path fill="url(#wholisticGradient)" d="M182.12 47.38c-1.13-1.83-1.69-3.9-1.69-6.18s.56-4.34 1.69-6.16 2.66-3.26 4.58-4.34c1.92-1.07 4.08-1.61 6.45-1.61 2.11 0 4.05.42 5.81 1.26s3.19 1.98 4.27 3.42l-4.32 3.87c-.65-1.05-1.46-1.85-2.45-2.35-.98-.52-2.04-.77-3.15-.77-1.2 0-2.3.29-3.3.86s-1.79 1.37-2.37 2.37c-.59 1.01-.87 2.17-.87 3.45s.29 2.45.87 3.48c.59 1.03 1.38 1.82 2.37 2.4s2.1.86 3.3.86c1.11 0 2.17-.25 3.15-.74.98-.5 1.8-1.29 2.45-2.37l4.32 3.87c-1.09 1.44-2.51 2.58-4.27 3.4s-3.69 1.26-5.81 1.26c-2.37 0-4.53-.54-6.45-1.61s-3.45-2.52-4.58-4.35v-.02Z" strokeWidth="0"/>
			<circle fill="url(#wholisticGradient)" cx="95.72" cy="41.57" r="11.77" strokeWidth="0"/>
		</svg>
	)
}

export default Wholistic