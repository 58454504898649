import React, { useEffect, useContext, useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import {
  Box,
  Grid,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure
} from '@chakra-ui/react'
import { gsap } from 'gsap'
import ColorContext from '../context/ColorContext'
import { colors, hexToRGB } from '../theme/themeAlopex'

const AKLogosMobileNew = () => {
  const { color } = useContext(ColorContext)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [title, setTitle] = useState('Client')
  const [logo, setLogo] = useState('')
  const [desc, setDesc] = useState('')
  const [hq, setHq] = useState('')
  const [est, setEst] = useState('')
  const [industry, setIndustry] = useState('')

  // Prepare Clients
  const { allClientsJson } = useStaticQuery(
    graphql`
      query {
        allClientsJson {
          nodes {
            title
            clientLogo
            desc
            hq
            established
            category
          }
        }
      }
    `
  )
  const clients = allClientsJson.nodes.map((c, i) => {
    return {
      title: c.title,
      clientLogo: c.clientLogo.replace('/static', ''),
      desc: c.desc,
      hq: c.hq,
      est: c.established,
      industry: c.category
    }
  })

  // Shuffle
  function shuffle(array) {
    let i = array.length
    let j = 0
    let temp
    while (i--) {
        j = Math.floor(Math.random() * (i+1))
        // swap randomly chosen element with current element
        temp = array[i]
        array[i] = array[j]
        array[j] = temp
    }
    return array
  }

  // Random Fill
  const randomFill = () => {
    const boxes = document.querySelectorAll('.logoGridMobile .item')

    // Shuffle
    shuffle(clients)

    // Assign shuffled clients to boxes and splice off
    // console.log('clients pre-slice', clients)
    for (let i = 0; i < boxes.length; i++) {
      gsap.set(boxes[i], {x: 0, y: 0, z: 0})
      boxes[i].firstChild.style.backgroundImage = 'url(' + clients[i]?.clientLogo + ')'
      boxes[i].firstChild.dataset.title = clients[i]?.title
      boxes[i].firstChild.dataset.logo = clients[i]?.clientLogo
      boxes[i].firstChild.dataset.desc = clients[i]?.desc
      boxes[i].firstChild.dataset.hq = clients[i]?.hq
      boxes[i].firstChild.dataset.est = clients[i]?.est
      boxes[i].firstChild.dataset.industry = clients[i]?.industry
      clients.splice(i, 1)
    }
    // console.log('clients post-slice', clients)
    
    // Repeating timeline
    const tlRep = new gsap.timeline({repeat: -1, duration: 2})
    
    // Choose random client and block to assign
    let last = 0
    let rand = 1
    tlRep.call(() => {
      // console.log('clientsBefore', clients)
      do {
        rand = Math.floor(Math.random() * (boxes.length))
      } while (rand === last)
      let randLogo = Math.floor(Math.random() * (clients.length))

      let tlSquish = gsap.timeline({repeat: 0, yoyo: true})
      tlSquish.to(boxes[rand], {boxShadow: '0px 0px 0px 0px rgba(255, 255, 255, 0.01), 0px 0px 0px 0px rgba(167, 179, 185, 0.01)', borderTopColor: ' rgba(255, 255, 255, 0.4)', borderLeftColor: ' rgba(255, 255, 255, 0.4)', duration: 1, ease: 'power1.inOut'})
      tlSquish.to(boxes[rand].firstChild, {opacity: 0, duration: 1, delay: -1, ease: 'power1.inOut'})
      tlSquish.set(boxes[rand].firstChild, {backgroundImage: 'url(' + clients[randLogo].clientLogo + ')'})
      tlSquish.to(boxes[rand].firstChild, {opacity: 1, duration: 1, delay: 0.5, ease: 'power1.inOut'})
      tlSquish.to(boxes[rand], {boxShadow: '-3px -3px 8px 1px rgba(255, 255, 255, 1), 3px 3px 8px 2px rgba(167, 179, 185, 1)', borderTopColor: ' rgba(255, 255, 255, 1)', borderLeftColor: ' rgba(255, 255, 255, 1)', duration: 1, delay: -1, ease: 'power1.inOut'})
      // console.log('boxes, clients', boxes[rand].dataset.logo, clients[randLogo].clientLogo)
      last = rand
      clients.push({
        title: boxes[rand].firstChild.dataset.title,
        clientLogo: boxes[rand].firstChild.dataset.logo,
        desc: boxes[rand].firstChild.dataset.desc,
        hq: boxes[rand].firstChild.dataset.hq,
        est: boxes[rand].firstChild.dataset.est,
        industry: boxes[rand].firstChild.dataset.industry,
      })
      boxes[rand].firstChild.dataset.title = clients[randLogo].title
      boxes[rand].firstChild.dataset.logo = clients[randLogo].clientLogo
      boxes[rand].firstChild.dataset.desc = clients[randLogo].desc
      boxes[rand].firstChild.dataset.hq = clients[randLogo].hq
      boxes[rand].firstChild.dataset.est = clients[randLogo].est
      boxes[rand].firstChild.dataset.industry = clients[randLogo].industry
      clients.splice(randLogo, 1)
    })
  }

  const handleModal = (e) => {
    const data = e.target.dataset
    if (data.title !== '') {
      setTitle(data.title)
      setLogo(data.logo)
      setDesc(data.desc)
      setHq(data.hq)
      setEst(data.est)
      setIndustry(data.industry)
      onOpen()
    }
  }

  // Run the magic
  useEffect(() => {
    if (typeof window !== 'undefined') {
      gsap.set(".item", {transformOrigin:"50% 50%"})
      randomFill()
    }
  }, [])
  
  return (
    <Box
      className="logoGridWrapper"
      sx={{
        '.logoGridMobile': {
          gridTemplateAreas: '1fr 1fr',
          gridGap: '20px 20px',
          '& .item': {
            // backgroundColor: '#37474f',
            paddingBottom: '60%',
            borderRadius: '10px',
            cursor: 'pointer',
            backgroundColor: '#f1f1f1',
            color: '#a7b3b9',
            boxShadow: '-3px -3px 8px 1px rgba(255, 255, 255, 1), 3px 3px 8px 2px rgba(167, 179, 185, 1)',
            borderTop: '1px solid rgba(255, 255, 255, 1)',
            borderLeft: '1px solid rgba(255, 255, 255, 1)',
            position: 'relative',
            '& .logo': {
              position: 'absolute',
              inset: 0,
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              backgroundSize: '140% 140%',
              transition: 'background-size 0.6s ease-in-out!important',
            }
          }
        }
      }}
    >
      <Box
        className="logoGridMobile"
        display="grid"
        gridTemplateColumns="1fr 1fr"
        margin="0 auto"
        width="100%"
      >
        <div className="item">
          <div className="logo" onClick={handleModal}/>
        </div>
        <div className="item">
          <div className="logo" onClick={handleModal}/>
        </div>
        <div className="item">
          <div className="logo" onClick={handleModal}/>
        </div>
        <div className="item">
          <div className="logo" onClick={handleModal}/>
        </div>
      </Box>
      <Modal isOpen={isOpen} onClose={onClose} size="xl" autoFocus={false} returnFocusOnClose={false}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton backgroundColor={hexToRGB('#37474f', 0.05)} color="#37474f" _hover={{backgroundColor: hexToRGB(color.alpha, 0.3)}} _focus={{boxShadow: 'none'}} />
          <ModalBody p="0 2rem 2rem">
            <Box as="img" maxW="415px" margin="0 auto" width="100%" src={logo} />
            <ModalHeader lineHeight="1" fontSize="4xl" p="0" mt="-2rem" mb="1rem" fontWeight="400">{title}</ModalHeader>
            <Box as="p" mb="40px">{desc}</Box>
            {hq !== 'null' && (  
              <Grid
                gridTemplateColumns={est !== 'null' && est !== '' ? '1fr 1fr 0.5fr' : '1fr 1fr'}
                textAlign="center"
                border={`1px solid ${hexToRGB(colors.brand.slate, 0.5)}`}
                borderRadius="5px"
                fontSize="14px"
                sx={{
                  '.statBox': {
                    lineHeight: '1.1',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '0.5rem 0.35rem',
                    'span': {
                      fontSize: '0.625rem'
                    },
                    'strong': {
                      fontWeight: '600'
                    }
                  },
                  '.statBox:not(:last-child)': {
                    borderRight: `1px solid ${hexToRGB(colors.brand.slate, 0.5)}`
                  }
                }}
              >
                <Box className="statBox">
                  <span>HQ</span><strong>{hq}</strong>
                </Box>
                <Box className="statBox">
                  <span>Industry</span><strong>{industry}</strong>
                </Box>
                {est !== 'null' && est !== '' && (
                  <Box className="statBox">
                    <span>Founded</span><strong>{est}</strong>
                  </Box>
                )}
              </Grid>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
      <style>{`
        .chakra-modal__overlay {
          background-color: rgba(255, 255, 255, 0.65);
          backdrop-filter: blur(4px);
        }
        .chakra-modal__content-container {
          padding-left: 1rem;
          padding-right: 1rem;
        }
        .chakra-modal__content {
          border-top: 2px solid white;
          border-left: 2px solid white;
          border-radius: 20px;
          background-color: #f1f1f1;
          box-shadow: -4px -4px 10px 1px white, 4px 4px 12px 1px #a7b3b9;
          color: #37474f;
        }
      `}</style>
    </Box>
  )
}

export default AKLogosMobileNew