import React, { useContext } from 'react'
import ColorContext from '../../context/ColorContext'

const Custom = ({ style }) => {
	const { color } = useContext(ColorContext)
	return (
		<svg
			id="svgCustom"
			className="svgInline"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 225 84.71"
			style={style}
		>
			<linearGradient id="customGradient" gradientUnits="userSpaceOnUse" x1="0" y1="43" x2="225" y2="43" gradientTransform="matrix(1 0 0 -1 0 86.71)">
				<stop  offset="0" style={{ stopColor: color.bravo }} />
				<stop  offset="1" style={{ stopColor: color.alpha }} />
			</linearGradient>
			<path fill="url(#customGradient)" strokeWidth="0" d="M130.36 28.1h-8.33v-7.66c0-.68-.52-1.05-1.11-1.05-.23 0-.52.08-.74.23l-4.92 3.19c-.82.52-.89 1.05-.89 1.63v3.64h-6.48c-.45 0-.89.37-.89.82v4.47c0 .45.45.82.89.89l6.48 1.34v13.54c-.32.38-.62.83-.93 1.34-2.01 3.05-4.54 5.66-7.22 7.59.74-1.34 1.19-2.9 1.19-4.54 0-6.77-5.13-12.5-6.85-17.86 2.01-3.42 3.05-5.72 3.05-7.06 0-1.86-1.34-3.19-3.87-3.19-2.76 0-5.95 3.42-5.95 8.33v1.05c-2.62 5.79-4.93 10.36-7.69 14.68-.23.3-.45.65-.68 1.02-3.35 5.21-7.37 9.96-10.49 9.96-1.05 0-2.45-1.05-2.45-3.72 0-3.64 1.71-8.11 4.24-13.4 2.82-4.54 3.79-6.69 3.79-7.88 0-1.42-1.05-2.45-2.6-2.45-2.16 0-3.87 1.19-5.8 5.72-2.01 4.69-4.47 10.35-7.06 14.06-2.82 4.09-5.58 7.22-8.33 7.22-.97 0-2.23-.89-2.23-3.27 0-3.64 1.71-8.11 4.24-13.4 2.82-4.54 3.79-6.63 3.79-7.82 0-1.48-1.11-2.53-2.82-2.53-2.16 0-3.87 1.48-6.32 6.32-1.46 2.85-3.05 6.27-4.19 9.59-.38.44-.74.97-1.13 1.56-5.13 7.88-15.7 11.69-23.88 11.69-9.67 0-15.93-5.88-15.93-15.48 0-18.46 17.78-34.97 32.29-35.05-2.53 3.19-4.16 6.85-4.16 10.72 0 5.88 4.09 9.45 10.19 9.45s11.53-4.98 11.53-12.2c0-4.09-1.71-7.66-4.98-10.19 1.48-.82 2.82-1.42 4.01-1.79 2.9-.89 3.79-1.42 3.79-2.76 0-1.86-1.19-2.98-3.5-2.98-3.27 0-7.51 1.71-11.32 4.47-1.71-.37-3.56-.6-5.58-.6C22.44 5.77.5 24.82.5 46.69c0 13.62 9.82 21.43 23.14 21.43 9.12 0 17.65-3.58 24.09-9.49.72 4.09 3.67 6.21 7.86 6.21 3.58 0 7.06-2.76 10.19-6.63.52 4.39 4.09 6.63 8.26 6.63 3.37 0 6.6-1.68 9.55-4.27 1.82 2.5 5.54 4.27 11.08 4.27 7.05 0 14.56-3.05 20.03-8.11 1.11 4.8 4.88 7.37 10.44 7.37h5.29c.45 0 .82-.37.82-.82v-4.84c0-.45-.37-.82-.82-.82h-4.61c-2.16 0-3.79-1.48-3.79-4.09V35.46h8.33c.45 0 .89-.37.89-.82v-5.72c0-.45-.45-.82-.89-.82ZM47.75 13.36c2.23 1.42 3.42 3.58 3.42 6.4 0 3.13-1.93 6.32-4.54 6.32-1.93 0-3.19-1.26-3.19-3.72 0-3.42 1.86-6.55 4.32-9Zm46.64 46.88c-3.64 0-5.13-2.76-5.13-4.01 0-1.05.3-2.23 1.79-4.54 2.16-3.27 4.01-6.48 5.66-9.67 1.79 3.79 3.72 7.22 3.72 11.83 0 3.5-2.31 6.4-6.03 6.4h-.01Z"/>
			<path fill="url(#customGradient)" strokeWidth="0" d="M153.27 27.35h-4.84c-8.11 0-14.2 4.76-14.2 13.54V51.3c0 8.79 6.1 13.54 14.2 13.54h4.84c8.11 0 14.2-4.76 14.2-13.54V40.89c0-8.79-6.1-13.54-14.2-13.54Zm6.54 23.96c0 4.76-3.19 7-7.74 7h-2.45c-4.54 0-7.74-2.23-7.74-7V40.82c0-4.69 3.19-6.92 7.74-6.92h2.45c4.54 0 7.74 2.23 7.74 6.92v10.49Z"/>
			<path fill="url(#customGradient)" strokeWidth="0" d="M215.49 27.35h-1.34c-4.32 0-6.55 2.39-9.22 5.66l-2.61 3.19c-.6-5.43-3.42-8.85-8.93-8.85h-1.34c-4.32 0-6.55 2.39-9.22 5.66l-2.45 2.98v-7.06c0-.45-.37-.82-.82-.82h-6.1c-.45 0-.82.37-.82.82V63.3c0 .45.37.82.82.82h6.1c.45 0 .82-.37.82-.82V44.7l4.84-5.88c2.08-2.53 3.95-4.84 5.88-4.84h.45c2.08 0 3.19 1.42 3.19 4.01v25.3c0 .45.37.82.82.82h6.1c.45 0 .82-.37.82-.82v-18.6l4.84-5.88c2.08-2.53 3.95-4.84 5.88-4.84h.45c2.08 0 3.19 1.42 3.19 4.01v25.3c0 .45.45.82.89.82h5.88c.45 0 .89-.37.89-.82v-25.3c0-7-3.35-10.64-9-10.64h-.01Z"/>
			<path fill="url(#customGradient)" strokeWidth="0" d="M141.11 69.56h12.4v4.78h-12.4z"/>
			<path fill="url(#customGradient)" strokeWidth="0" d="M103.92 69.56c-44.87.04-81.33 1.96-97.71 5.15-1.25.24-2.08 1.4-1.91 2.66s1.26 2.15 2.54 2.07c2.68-.19 10.63-.03 15.08 2.98.41.28.87.41 1.34.41.77 0 1.52-.37 1.97-1.05.74-1.09.45-2.58-.64-3.31-.64-.44-1.31-.82-2.01-1.15 19.28-1.89 48.52-2.98 82.62-2.98h11.13v-4.78h-12.42Z"/>
			<path fill="url(#customGradient)" strokeWidth="0" d="M122.51 69.56h12.4v4.78h-12.4z"/>
			<path fill="url(#customGradient)" strokeWidth="0" d="M159.72 69.56h12.4v4.78h-12.4z"/>
			<path fill="url(#customGradient)" strokeWidth="0" d="M178.31 69.56h12.4v4.78h-12.4z"/>
			<path fill="url(#customGradient)" strokeWidth="0" d="M215.52 69.56h8.97v4.78h-8.97z"/>
			<path fill="url(#customGradient)" strokeWidth="0" d="M196.91 69.56h12.4v4.78h-12.4z"/>
		</svg>
	)
}

export default Custom