import React, { useEffect, useContext, createRef, useRef, useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import {
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Grid
} from '@chakra-ui/react'
import { gsap } from 'gsap'
import ColorContext from '../context/ColorContext'
import { colors, hexToRGB } from '../theme/themeAlopex'

const AKLogosNew = () => {
  const { color } = useContext(ColorContext)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [title, setTitle] = useState('Client')
  const [logo, setLogo] = useState('')
  const [desc, setDesc] = useState('')
  const [hq, setHq] = useState('')
  const [est, setEst] = useState('')
  const [industry, setIndustry] = useState('')

  // Prepare Clients
  const { allClientsJson } = useStaticQuery(
    graphql`
      query {
        allClientsJson {
          nodes {
            title
            clientLogo
            desc
            hq
            established
            category
          }
        }
      }
    `
  )
  const clients = allClientsJson.nodes.map((c, i) => {
    return {
      title: c.title,
      clientLogo: c.clientLogo.replace('/static', ''),
      desc: c.desc,
      hq: c.hq,
      est: c.established,
      industry: c.category
    }
  })

  // Shuffle
  function shuffle(array) {
    let i = array.length
    let j = 0
    let temp
    while (i--) {
        j = Math.floor(Math.random() * (i+1))
        // swap randomly chosen element with current element
        temp = array[i]
        array[i] = array[j]
        array[j] = temp
    }
    return array
  }

  // Random Fill
  const randomFill = () => {
    const boxes = document.querySelectorAll('.logoGrid .item:not(.square)')

    // Shuffle
    shuffle(clients)

    // Assign shuffled clients to boxes and splice off
    // console.log('clients pre-slice', clients)
    for (let i = 0; i < boxes.length; i++) {
      gsap.set(boxes[i], {x: 0, y: 0, z: 0})
      boxes[i].firstChild.style.backgroundImage = 'url(' + clients[i]?.clientLogo + ')'
      boxes[i].firstChild.dataset.title = clients[i]?.title
      boxes[i].firstChild.dataset.logo = clients[i]?.clientLogo
      boxes[i].firstChild.dataset.desc = clients[i]?.desc
      boxes[i].firstChild.dataset.hq = clients[i]?.hq
      boxes[i].firstChild.dataset.est = clients[i]?.est
      boxes[i].firstChild.dataset.industry = clients[i]?.industry
      clients.splice(i, 1)
    }
    // console.log('clients post-slice', clients)
    
    // Repeating timeline
    const tlRep = new gsap.timeline({repeat: -1, duration: 1})
    
    // Choose random client and block to assign
    let last = 0
    let rand = 1
    tlRep.call(() => {
      // console.log('clientsBefore', clients)
      do {
        rand = Math.floor(Math.random() * (boxes.length))
      } while (rand === last)
      let randLogo = Math.floor(Math.random() * (clients.length))

      let tlSquish = gsap.timeline({repeat: 0, yoyo: true})
      tlSquish.to(boxes[rand], {boxShadow: '1px 1px 1px 0px rgba(255, 255, 255, 0.15), 1px 1px 1px 0px rgba(167, 179, 185, 0.15)', borderTopColor: ' rgba(255, 255, 255, 0.2)', borderLeftColor: ' rgba(255, 255, 255, 0.2)', duration: 1, ease: 'power1.inOut'})
      tlSquish.to(boxes[rand].firstChild, {opacity: 0, duration: 1, delay: -1, ease: 'power1.inOut'})
      tlSquish.set(boxes[rand].firstChild, {backgroundImage: 'url(' + clients[randLogo].clientLogo + ')'})
      tlSquish.to(boxes[rand].firstChild, {opacity: 1, duration: 1, delay: 0.5, ease: 'power1.inOut'})
      tlSquish.to(boxes[rand], {boxShadow: '-3px -3px 8px 1px rgba(255, 255, 255, 1), 3px 3px 8px 2px rgba(167, 179, 185, 1)', borderTopColor: ' rgba(255, 255, 255, 1)', borderLeftColor: ' rgba(255, 255, 255, 1)', duration: 1, delay: -1, ease: 'power1.inOut'})
      // console.log('boxes, clients', boxes[rand].dataset.logo, clients[randLogo].clientLogo)
      last = rand
      clients.push({
        title: boxes[rand].firstChild.dataset.title,
        clientLogo: boxes[rand].firstChild.dataset.logo,
        desc: boxes[rand].firstChild.dataset.desc,
        hq: boxes[rand].firstChild.dataset.hq,
        est: boxes[rand].firstChild.dataset.est,
        industry: boxes[rand].firstChild.dataset.industry,
      })
      boxes[rand].firstChild.dataset.title = clients[randLogo].title
      boxes[rand].firstChild.dataset.logo = clients[randLogo].clientLogo
      boxes[rand].firstChild.dataset.desc = clients[randLogo].desc
      boxes[rand].firstChild.dataset.hq = clients[randLogo].hq
      boxes[rand].firstChild.dataset.est = clients[randLogo].est
      boxes[rand].firstChild.dataset.industry = clients[randLogo].industry
      clients.splice(randLogo, 1)
    })
  }

  const handleModal = (e) => {
    const data = e.target.dataset
    if (data.title !== '') {
      setTitle(data.title)
      setLogo(data.logo)
      setDesc(data.desc)
      setHq(data.hq)
      setEst(data.est)
      setIndustry(data.industry)
      onOpen()
    }
  }

  // Run the magic
  useEffect(() => {
    if (typeof window !== 'undefined') {
      gsap.set(".item", {transformOrigin:"50% 50%"})
      randomFill()
    }
  }, [])
  
  return (
    <Box
      className="logoGridWrapper"
      sx={{
        '.logoGrid': {
          gridTemplateAreas: `
                              ". . . . . . . a1 a1 . . . . . . ."
                              ". . . . . b1 b1 b2 b2 b3 b3 . . . . ."
                              ". . . c1 c1 c2 c3 c3 c3 c4 c4 c4 . . . ."
                              ". . . . d1 d1 c3 c3 c3 c4 c4 c4 . . . ."
                              ". . e1 . e2 e2 e2 e2 e2 e3 e3 e3 . . . ."
                              ". . . . e2 e2 e2 e2 e2 e3 e3 e3 . . . ."
                              ". . . g1 e2 e2 e2 e2 e2 g2 g2 g2 . . . ."
                              ". . h1 h1 h2 h2 h3 h3 h3 g2 g2 g2 h4 h4 . ."
                              ". . . . i1 i1 h3 h3 h3 . . i2 i2 i3 i3 ."
                              ". . . . . . j1 j2 j2 . . . . . j3 j3"
                              ". . . . k1 k1 k2 k2 . . . . . . . k3"
                              ". . l1 l1 l2 l2 . . . . . . . . . ."
                              "m1 m2 m2 . . . . . . . . . . . . ."
                             `,
          gridGap: '8px',
          '& .item': {
            // backgroundColor: '#37474f',
            paddingBottom: '50%',
            borderRadius: '8px',
            cursor: 'pointer',
            backgroundColor: '#f1f1f1',
            boxShadow: '-3px -3px 8px 1px rgba(255, 255, 255, 1), 3px 3px 8px 2px rgba(167, 179, 185, 1)',
            borderTop: '1px solid rgba(255, 255, 255, 1)',
            borderLeft: '1px solid rgba(255, 255, 255, 1)',
            position: 'relative',
            '& .logo': {
              position: 'absolute',
              inset: 0,
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              backgroundSize: '140% 140%',
              transition: 'background-size 0.6s ease-in-out!important',
            }
          },
          '.a1': { gridArea: 'a1' },
          '.b1': { gridArea: 'b1' },
          '.b2': { gridArea: 'b2' },
          '.b3': { gridArea: 'b3' },
          '.c1': { gridArea: 'c1' },
          '.c2': { gridArea: 'c2' },
          '.c3': { gridArea: 'c3' },
          '.c4': { gridArea: 'c4' },
          '.d1': { gridArea: 'd1' },
          '.e1': { gridArea: 'e1' },
          '.e2': { gridArea: 'e2' },
          '.e3': { gridArea: 'e3' },
          '.g1': { gridArea: 'g1' },
          '.g2': { gridArea: 'g2' },
          '.h1': { gridArea: 'h1' },
          '.h2': { gridArea: 'h2' },
          '.h3': { gridArea: 'h3' },
          '.h4': { gridArea: 'h4' },
          '.i1': { gridArea: 'i1' },
          '.i2': { gridArea: 'i2' },
          '.i3': { gridArea: 'i3' },
          '.j1': { gridArea: 'j1' },
          '.j2': { gridArea: 'j2' },
          '.j3': { gridArea: 'j3' },
          '.k1': { gridArea: 'k1' },
          '.k2': { gridArea: 'k2' },
          '.k3': { gridArea: 'k3' },
          '.l1': { gridArea: 'l1' },
          '.l2': { gridArea: 'l2' },
          '.m1': { gridArea: 'm1' },
          '.m2': { gridArea: 'm2' }  
        }
      }}
    >
      <Box
        className="logoGrid"
        display="grid"
        gridTemplateColumns="1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr"
        gridTemplateRows="1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr"
        gridAutoFlow="row"
        margin="0 auto"
        maxWidth="1280px"
        width="100%"
      >
        <div className="item bigItem c3"><div className="logo" onClick={handleModal}/></div>
        <div className="item bigItem c4"><div className="logo" onClick={handleModal}/></div>
        <div className="item bigItem e2"><div className="logo" onClick={handleModal}/></div>
        <div className="item bigItem e3"><div className="logo" onClick={handleModal}/></div>
        <div className="item bigItem g2"><div className="logo" onClick={handleModal}/></div>
        <div className="item bigItem h3"><div className="logo" onClick={handleModal}/></div>
        <div className="item a1"><div className="logo" onClick={handleModal}/></div>
        <div className="item b1"><div className="logo" onClick={handleModal}/></div>
        <div className="item b2"><div className="logo" onClick={handleModal}/></div>
        <div className="item b3"><div className="logo" onClick={handleModal}/></div>
        <div className="item c1"><div className="logo" onClick={handleModal}/></div>
        <div className="item c2 square"></div>
        <div className="item d1"><div className="logo" onClick={handleModal}/></div>
        <div className="item e1 square"></div>
        <div className="item g1 square"></div>
        <div className="item h1"><div className="logo" onClick={handleModal}/></div>
        <div className="item h2"><div className="logo" onClick={handleModal}/></div>
        <div className="item h4"><div className="logo" onClick={handleModal}/></div>
        <div className="item i1"><div className="logo" onClick={handleModal}/></div>
        <div className="item i2"><div className="logo" onClick={handleModal}/></div>
        <div className="item i3"><div className="logo" onClick={handleModal}/></div>
        <div className="item j1 square"></div>
        <div className="item j2"><div className="logo" onClick={handleModal}/></div>
        <div className="item j3"><div className="logo" onClick={handleModal}/></div>
        <div className="item k1"><div className="logo" onClick={handleModal}/></div>
        <div className="item k2"><div className="logo" onClick={handleModal}/></div>
        <div className="item k3 square"></div>
        <div className="item l1"><div className="logo" onClick={handleModal}/></div>
        <div className="item l2"><div className="logo" onClick={handleModal}/></div>
        <div className="item m1 square"></div>
        <div className="item m2"><div className="logo" onClick={handleModal}/></div>
      </Box>
      <Modal isOpen={isOpen} onClose={onClose} size="xl" isCentered autoFocus={false} returnFocusOnClose={false}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton backgroundColor={hexToRGB('#37474f', 0.05)} color="#37474f" _hover={{backgroundColor: hexToRGB(color.alpha, 0.3)}} _focus={{boxShadow: 'none'}} />
          <ModalBody p="0 3rem 3rem">
            <Box as="img" maxW="415px" margin="0 auto" width="100%" src={logo} />
            <ModalHeader lineHeight="1" fontSize="4xl" p="0" mt="-2rem" mb="1rem" fontWeight="400">{title}</ModalHeader>
            <Box as="p" mb="40px">{desc}</Box>
            {hq !== 'null' && (
              <Grid
                gridTemplateColumns={est !== 'null' && est!== '' ? '1fr 1fr 0.5fr' : '1fr 1fr'}
                textAlign="center"
                border={`1px solid ${hexToRGB(colors.brand.slate, 0.5)}`}
                borderRadius="5px"
                fontSize="0.875rem"
                sx={{
                  '.statBox': {
                    lineHeight: '1.1',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '0.5rem 0.35rem',
                    'span': {
                      fontSize: '0.625rem'
                    },
                    'strong': {
                      fontWeight: '600'
                    }
                  },
                  '.statBox:not(:last-child)': {
                    borderRight: `1px solid ${hexToRGB(colors.brand.slate, 0.5)}`
                  }
                }}
              >
                <Box className="statBox">
                  <span>HQ</span><strong>{hq}</strong>
                </Box>
                <Box className="statBox">
                  <span>Industry</span><strong>{industry}</strong>
                </Box>
                {est !== 'null' && est !== '' && (
                  <Box className="statBox">
                    <span>Founded</span><strong>{est}</strong>
                  </Box>
                )}
              </Grid>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
      <style>{`
        .chakra-modal__overlay {
          background-color: rgba(255, 255, 255, 0.65);
          backdrop-filter: blur(4px);
          -webkit-backdrop-filter: blur(4px);
        }
        .chakra-modal__content {
          border-top: 2px solid white;
          border-left: 2px solid white;
          border-radius: 20px;
          background-color: #f1f1f1;
          box-shadow: -4px -4px 10px 1px white, 4px 4px 12px 1px #a7b3b9;
          color: #37474f;
        }
      `}</style>
    </Box>
  )
}

export default AKLogosNew