import React, { useEffect, useState } from 'react'
import {
  Box,
  Grid,
  Container,
} from '@chakra-ui/react'
import { useStaticQuery, graphql } from 'gatsby'
import { spacing, colors, hexToRGB } from "../theme/themeAlopex"
import { H2 } from '../theme/Headings'
// SVG Components
import MadeInUSA from '../components/WhyIcons/MadeInUSA'
import Certified from '../components/WhyIcons/Certified'
import DigitalArchitects from '../components/WhyIcons/DigitalArchitects'
import Local from '../components/WhyIcons/Local'
import AppIcons from '../components/WhyIcons/AppIcons'
import Vetted from '../components/WhyIcons/Vetted'
import DesDev from '../components/WhyIcons/DesDev'
import Flexible from '../components/WhyIcons/Flexible'
import IncredibleSEO from '../components/WhyIcons/IncredibleSEO'
import Custom from './WhyIcons/Custom'
import Wholistic from './WhyIcons/Wholistic'
import Superior from './WhyIcons/Superior'

// Get reasons icon component
const getReasonIcon = (icon, style = {}) => {
  switch (icon) {
    case 'miusa':
      return <MadeInUSA style={style} />
    case 'certified':
      return <Certified style={style} />
    case 'digital':
      return <DigitalArchitects style={style} />
    case 'desdev':
      return <DesDev style={style} />
    case 'seo':
      return <IncredibleSEO style={style} />
    case 'flexible':
      return <Flexible style={style} />
    case 'local':
      return <Local style={style} />
    case 'vetted':
      return <Vetted style={style} />
    case 'appicons':
      return <AppIcons style={style} />
    case 'custom':
      return <Custom style={style} />
    case 'wholistic':
      return <Wholistic style={style} />
    case 'superior':
      return <Superior style={style} />
    default:
      return <MadeInUSA style={style} />
  }
}

const Masonry = ({reasons, photoCats}) => {

// Shuffle
function shuffle(array) {
  let i = array.length
  let j = 0
  let temp
  while (i--) {
      j = Math.floor(Math.random() * (i+1))
      // swap randomly chosen element with current element
      temp = array[i]
      array[i] = array[j]
      array[j] = temp
  }
  return array
}

  // Prepare Photos
  const { allServicePhotosJson } = useStaticQuery(
    graphql`
      query {
        allServicePhotosJson {
          nodes {
            title
            photo
            caption
            category
          }
        }
      }
    `
  )

  const filteredPhotos = allServicePhotosJson.nodes.filter((photo) => photoCats.includes(photo.category))
  const filteredPhotosMobile = allServicePhotosJson.nodes.filter((photo) => photoCats.includes(photo.category))
  
  // Fill with office photos if minimum not met
  const officePhotos = allServicePhotosJson.nodes.filter((photo) => photo.category === 'Office')
  
  // Desktop (min: 8)
  // console.log('filteredPhotos Before', filteredPhotos)
  if (filteredPhotos.length < 8) {
    for (let i = 0; filteredPhotos.length < 8; i++) {
      filteredPhotos.push(officePhotos[i])
    }
  }
  // console.log('filteredPhotos After', filteredPhotos)
  
  // Mobile (min: 4)
  // console.log('filteredPhotosMobile Before', filteredPhotosMobile)
  if (filteredPhotosMobile.length < 4) {
    for (let i = 0; filteredPhotosMobile.length < 4; i++) {
      filteredPhotosMobile.push(officePhotos[i])
    }
  }
  // console.log('filteredPhotos After', filteredPhotosMobile)


  const [photos] = useState(Array.isArray(filteredPhotos) ? shuffle(filteredPhotos) : [])
  const [photosMobile] = useState(Array.isArray(filteredPhotosMobile) ? shuffle(filteredPhotosMobile) : [])
  const rClasses = ['a2', 'b1', 'c2']
  const rClassesMobile = ['a1', 'd1', 'g1']
  const rPhotoClasses = ['a1', 'a3', 'a4', 'a5', 'c1', 'c3', 'd1', 'd2']
  const rPhotoClassesMobile = ['c1', 'c2', 'f1', 'i1']

  const [isDesktop, setDesktop] = useState(false);
  const updateMedia = () => {
    if (window.innerWidth > 1200) {
      setDesktop(true);
    } else {
      setDesktop(false);
    }
  }

  useEffect(() => {
    updateMedia()
    window.addEventListener('resize', updateMedia)
    return () => window.removeEventListener('resize', updateMedia)
  }, [])

  return (
    
    <Container position="relative" maxWidth="1920px" mb={[spacing.groups, null, spacing.section]}>
      <Grid
        gridAutoColumns="1fr"
        gridTemplateColumns="1fr 1fr"
        gridTemplateRows="1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr"
        gridGap="20px 20px"
        gridTemplateAreas={`
          "a1 a1"
          "a1 a1"
          "d1 d1"
          "d1 d1"
          "g1 g1"
          "g1 g1"
          "c1 c2"
          "f1 f1"
          "i1 i1"
          "i1 i1"
        `}
        sx={{
          '.a1': { gridArea: 'a1' },
          '.c1': { gridArea: 'c1' },
          '.c2': { gridArea: 'c2' },
          '.d1': { gridArea: 'd1' },
          '.f1': { gridArea: 'f1' },
          '.g1': { gridArea: 'g1' },
          '.i1': { gridArea: 'i1' },
          '.rBlock': {
            color: '#a7b3b9',
            backgroundColor: '#f1f1f1',
            paddingBottom: '35%',
            // backgroundImage: `linear-gradient(135deg, #f1f1f1 30%, #e7e7e7)`,
            borderRadius: '10px',
            position: 'relative',
            overflow: 'hidden',
            '.rBlockInner': {
              position: 'absolute',
              inset: 0,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              justifyContent: 'center',
              backgroundSize: 'cover',
              backgroundPosition: '50% 50%',
              padding: '2rem',
              svg: {
                minHeight: '28px'
              },
              '.rCaption': {
                backgroundColor: 'rgba(255, 255, 255, 0.8)',
                color: colors.brand.slate,
                fontSize: '0.875rem',
                left: '-100%',
                position: 'absolute',
                maxWidth: '90%',
                borderTopRightRadius: '10px',
                borderBottomRightRadius: '10px',
                bottom: '10px',
                padding: '10px 16px',
                transition: 'left 0.3s ease-in-out',
              },
              '&:hover .rCaption': {
                left: 0
              }
            }
          },
          '@media (min-width: 1200px)': {
            '.rBlock': {
              paddingBottom: '50%'
            },
            gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr',
            gridTemplateRows: '1fr 1fr 1fr 1fr',
            gridTemplateAreas: `
              "a1 a2 a2 a3 a3 a4 a4 a5"
              "a1 a2 a2 b1 b1 a4 a4 a5"
              "c1 c1 c1 b1 b1 c2 c2 c3"
              "c1 c1 c1 d1 d2 c2 c2 c3"
          `,
          '.a1': { gridArea: 'a1'},
          '.a2': { gridArea: 'a2'},
          '.a3': { gridArea: 'a3'},
          '.a4': { gridArea: 'a4'},
          '.a5': { gridArea: 'a5'},
          '.b1': { gridArea: 'b1'},
          '.c1': { gridArea: 'c1'},
          '.c2': { gridArea: 'c2'},
          '.c3': { gridArea: 'c3'},
          '.d1': { gridArea: 'd1'},
          '.d2': { gridArea: 'd2'}
          },
        }}
      >
        {/* Desktop masonry */}
        {isDesktop && (
          <>
            {/* Icons */}
            {!!reasons && reasons.map((reason, i) => {
              return (
                <Box
                  className={`rBlock ${rClasses[i]}`}
                  key={`key-reasons-${i}`}
                  border="1px solid #d8d8d8"
                >
                  <Box className="rBlockInner">
                    {!!reason?.icon &&
                      <>
                        {getReasonIcon(reason?.icon, { width: '100%', height: 'auto', maxWidth: '50%', marginBottom: '1rem' })}
                        <H2 variant="figtreeSm" color={hexToRGB(colors.brand.slate, 0.3)} style={{marginBottom: '1rem'}}>{reason?.title}</H2>
                        <Box color={colors.brand.slate} as="p" mb="0">{reason?.blurb}</Box>
                      </>
                    }
                  </Box>
                </Box>
              )
            })}
            {/* Photos */}
            {photos.length >= 1 && rPhotoClasses.map((rClass, i) => {
              return (
                <Box
                  className={`rBlock ${rClass} morphicShadow`}
                  key={`key-reasons-photos-${i}`}
                  data-cat={photos[i].category}
                >
                  <Box className="rBlockInner" backgroundImage={!!photos[i]?.photo ? `url(${photos[i].photo.replace('/static', '')})` : null}>
                    <Box className="rCaption">{photos[i]?.caption}</Box>
                  </Box>
                </Box>
              )
            })}
          </>
        )}

        {/* Mobile masonry */}
        {!isDesktop && (
          <>
          {/* Icons */}
          {!!reasons && reasons.map((reason, i) => {
            return (
              <Box
                className={`rBlock ${rClassesMobile[i]}`}
                key={`key-reasons-mobile-${i}`}
                border="1px solid #d8d8d8"
              >
                <Box className="rBlockInner">
                  {!!reason?.icon &&
                    <>
                      {getReasonIcon(reason?.icon, { width: '100%', height: 'auto', maxWidth: '50%', marginBottom: '1rem' })}
                      <H2 variant="figtreeSm" color={hexToRGB(colors.brand.slate, 0.3)} style={{marginBottom: '1rem'}}>{reason?.title}</H2>
                      <Box color={colors.brand.slate} as="p" mb="0">{reason?.blurb}</Box>
                    </>
                  }
                </Box>
              </Box>
            )
          })}
          {/* Photos */}
          {photosMobile.length >= 1 && rPhotoClassesMobile.map((rClass, i) => {
            return (
              <Box
                className={`rBlock ${rClass} morphicShadow`}
                key={`key-reasons-photos-mobile-${i}`}
                data-cat={photosMobile[i].category}
              >
                <Box className="rBlockInner" backgroundImage={!!photosMobile[i]?.photo ? `url(${photosMobile[i].photo.replace('/static', '')})` : null}>
                  <Box className="rCaption">{photosMobile[i]?.caption}</Box>
                </Box>
              </Box>
            )
          })}
        </>
      )}
      </Grid>
    </Container>
  )
}

export default Masonry