import React, {useEffect, useRef, useContext} from 'react'
import {Box} from '@chakra-ui/react'
import ColorContext from '../context/ColorContext'
import {hexToRGB, colors} from '../theme/themeAlopex'

const VideoBox = ({videoURL, videoThumb, videoCCTrack}) => {
  const refVid = useRef(null)
  const refVidPlay = useRef(null)
  const { color } = useContext(ColorContext)
  useEffect(() => {
    if (refVid.current) {
      // Listen for end of video and reload for persistent video poster (thumbnail)
      refVid.current.addEventListener('ended', () => {
        refVid.current.load()
      })
      // Show video controls on hover
      refVid.current.controls = false
      refVid.current.addEventListener('mouseover', () => {
        refVid.current.controls = 'controls'
        refVidPlay.current.classList.add('active')
      })
      refVid.current.addEventListener('mouseout', () => {
        refVid.current.controls = false
        refVidPlay.current.classList.remove('active')
      })
      refVid.current.addEventListener("playing", (event) => {
        refVidPlay.current.classList.add('disabled')
      });
    }
  }, [])

  const handlePlay = () => {

  }
  return (
    <Box
      position="relative"
      pb="100%"
      height="0"
      overflow="hidden"
      borderRadius="10px"
      cursor="pointer"
      boxShadow={`
        0.7px 0.7px 0.9px -3px rgba(0, 0, 0, 0.034),
        1.7px 1.7px 2.1px -3px rgba(0, 0, 0, 0.048),
        3.1px 3.1px 4px -3px rgba(0, 0, 0, 0.06),
        5.6px 5.6px 7.1px -3px rgba(0, 0, 0, 0.072),
        10.4px 10.4px 13.4px -3px rgba(0, 0, 0, 0.086),
        25px 25px 32px -3px rgba(0, 0, 0, 0.12)
      `}
      sx={{
        '.video::-webkit-media-controls-panel': {
          background: `linear-gradient(${hexToRGB(color.bravo, 0.01)} 80%, ${hexToRGB(color.alpha, 0.65)})`
        }
      }}
    >
      <video
        className="video"
        ref={refVid}
        width="100%"
        height="100%"
        poster={videoThumb !== '' ? videoThumb.replace('/static', '') : '/services/2024/video/video-poster-square.webp'}
      >
        <source src={`https://alopex.mo.cloudinary.net${videoURL.replace('/static', '')}?type=auto-upload`} type="video/mp4"/>
        {!!videoCCTrack &&
          <track
            kind="captions"
            srcLang="en"
            src={videoCCTrack.replace('/static', '')}
          />
        }
        Your browser does not support the video tag.
      </video>
      <Box
        ref={refVidPlay}
        borderRadius="999px"
        position="absolute"
        left="50%"
        top="50%"
        transform="translate(-50%, -50%)"
        width="100px"
        height="100px"
        display="flex"
        justifyContent="center"
        alignItems="center"
        cursor="pointer"
        backgroundColor="rgba(255, 255, 255, 0.9)"
        backdropFilter="blur(3px)"
        pointerEvents="none"
        color={hexToRGB(colors.brand.slate, 0.75)}
        sx={{
          '&.active': {
            backgroundColor: hexToRGB(color.alpha, 0.5),
            color: 'white'
            // boxShadow: '-1px -1px 4px 1px rgba(255, 255, 255, 1), 1px 1px 4px 1px currentColor !important'
          },
          '&.disabled': {
            display: 'none'
          },
          svg: {
            width: '100%'
          }
        }}
      >
        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 100 100">
          <circle cx="50" cy="50" r="41.999" fill="none" stroke="currentColor" strokeMiterlimit="10" strokeWidth="5" style={{display: 'none'}}></circle>
          <path d="M41.489,69.958V30.042c0-1.919,2.32-2.88,3.677-1.523l19.958,19.958c.841.841.841,2.205,0,3.046l-19.958,19.958c-1.357,1.357-3.677.396-3.677-1.523Z" fill="currentColor" strokeWidth="0"></path>
        </svg>
      </Box>
    </Box>
  )
}

export default VideoBox